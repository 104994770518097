import React, { Component } from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { getUser, signOut } from "../src/store/actions/authActions"
import { compose } from "redux"
import { connect } from "react-redux"
import { firestoreConnect } from "react-redux-firebase"
import BetterRouter from './BetterRouter'
import DefaultLayout from './containers/DefaultLayout'
import Login from './views/Pages/Login'
import Error404 from './views/Pages/Page404'

import './App.scss';
import { verifyAdmin } from './store/actions/authActions'

//Floating notifications
import Notifications from './views/Notifications/Notifications'

const loading = () => <div className="animated fadeIn pt-3 text-center">Cargando...</div>;


class App extends Component {

  componentWillReceiveProps(props){
    props.verifyAdmin()
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Cargando...</div>
  )

  notLogin = (
    <div>
      <div className="messageDontEnter">
        <p>
          
        </p>
      </div>
    </div>
  )

  render() {

    const { auth } = this.props

    if (auth.isLoaded) {
      return (
        <div>
          <Notifications />
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" component={Login}/>
              <BetterRouter path='/' component={DefaultLayout} {...this.props} />
              <Route path="*" component={Error404} />
            </Switch>
          </BrowserRouter>
        </div>
      );
    } else {
      return loading();
    }
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth || {},
    user: (state.firestore.data.superusers || [])[state.firebase.auth.uid],
    notification: state.notifications.notification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUser: userId => dispatch(getUser(userId)),
    signOut: () => dispatch(signOut()),
    verifyAdmin: () => dispatch(verifyAdmin())
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => [
  ])
)(App);
