import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { reduxFirestore, firestoreReducer, getFirestore } from 'redux-firestore'
import { reactReduxFirebase, firebaseReducer, getFirebase } from 'react-redux-firebase'
import firebase from './firebaseConfig'
import authReducer from '../store/reducers/authReducer'
import notificationReducer from '../store/reducers/notificationReducer'
import uxReducer from '../store/reducers/uxReducer'
import envReducer from '../store/reducers/envReducer'
import thunk from 'redux-thunk'

const rootReducer = combineReducers({
   firebase: firebaseReducer,
   firestore: firestoreReducer,
   auth: authReducer,
   notifications: notificationReducer,
   ux: uxReducer,
   env: envReducer
})

const rrfConfig = {
   userProfile: 'superusers',
   useFirestoreForProfile: true,
   preserveOnDelete: true,
   mergeOrdered: false,
}

const createStoreWithFirebase = compose(
   applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
   reactReduxFirebase(firebase, rrfConfig),
   reduxFirestore(firebase)
)(createStore)

const initialState = {
   firestore: {
      ordered: {
      },
      data: {
      }
   }
}

export default createStoreWithFirebase(rootReducer, initialState)