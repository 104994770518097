const initState = {
   notifications: []
}

const notificationReducer = (state = initState, action) => {
   switch (action.type) {
      case 'SHOW_NOTIFICATION':
         return {
            ...state,
            notifications: [...state.notifications, action.notification]
         }
      case 'DELETE_NOTIFICATION':
         return {
            ...state,
            notifications: state.notifications.filter((_, idx) => idx !== action.idx)
         }
      default:
         return state
   }
}
 
export default notificationReducer