import React from 'react'

import { connect } from 'react-redux'

import { Alert } from 'reactstrap'

import { dismissNotification } from '../../store/actions/notificationsActions'

const Notifications = props => {

  const onDismiss = (idx) => {
    props.dismissNotification(idx)
  }

  return(<div className="notifications-container">
    {
      props.notifications.map((notification, idx) =>
        <Alert color={notification.type}
          toggle={(e) => {
            e.persist()
            return onDismiss(idx)
          }}
          isOpen={notification}
        >
          {
            notification.title && 
            <h4 className="notification-item-header">
              {notification.title}
            </h4>
          }
          {
            notification.message && 
            <p className="notification-item-message">
              {notification.message}
            </p>
          }
        </Alert>
      )
    }
  </div>)

}

const mapStateToProps = state => {
  return {
    notifications: state.notifications.notifications
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dismissNotification: (idx) => {
      dispatch(dismissNotification(idx))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)