//Initial state
const initState = {
  mode: process.env.NODE_ENV, //Environment
  cloudFunctionsPrefix: 'https://us-central1-luckytrade2021.cloudfunctions.net' //Https cloud functions prefix
}

/**
 * @param {Object} state redux store state
 * @param {Object} action has the type to select how to update the state
 */
const envReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_ENV': //Update the environment
       return {
          ...state,
          mode: process.env.NODE_ENV
       }
    default:
       return state
  }
}

export default envReducer