export default {
  /**
   * Regular user navbar
   */
  items: [
    {
      name: 'Home',
      url: '/',
      icon: 'icon-home',
    },
    {
      title: true,
      name: 'Páginas',
    }, 
    {
      name: 'Páginas',
      icon: 'icon-options',
      url: '/pages',
      children: [
        {
          name: 'LuckyTrade Home',
          url: '/lucky-trade-home',
          icon: 'icon-globe',
        },
        {
          name: 'LuckyTrade Industrias',
          url: '/lucky-trade-industries',
          icon: 'icon-globe',
        },
        {
          name: 'LuckyTrade Nosotros',
          url: '/lucky-trade-us',
          icon: 'icon-globe',
        },
        {
          name: 'LuckyTrade Servicios',
          url: '/lucky-trade-services',
          icon: 'icon-globe',
        },
        {
          name: 'LuckyTrade Navbar',
          url: '/lucky-trade-navbar',
          icon: 'icon-globe',
        },
        {
          name: 'LuckyTrade Footer',
          url: '/lucky-trade-footer',
          icon: 'icon-globe',
        },
      ]
    },
    {
      title: true,
      name: 'Otros contenidos',
    },
    {
      name: 'Industrias',
      url: '/industries',
      icon: 'icon-briefcase',
    },
    {
      name: 'Clientes',
      url: '/clients',
      icon: 'icon-people',
    },
    {
      name: 'Proveedores',
      url: '/providers',
      icon: 'icon-people',
    },
    {
      name: 'Contacto',
      url: '/company',
      icon: 'icon-globe',
    },
  ]
}