
  //Initial state
const initState = {
  uploadingData: false //Data is beign uploaded to the server
}

/**
 * 
 * @param {Object} state redux store state
 * @param {Object} action has the type to select how to update the state
 */
const uxReducer = (state = initState, action) => {
  switch (action.type) {
    case 'START_UPLOAD':
       return {
          ...state,
          uploadingData: true
       }
    case 'FINISH_UPLOAD':
       return {
          ...state,
          uploadingData: false
       }
    default:
       return state
 }
}

export default uxReducer