export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    
    const firebase = getFirebase()
    const firestore = getFirestore()

    //Check current environment
    let firebaseEnv = ''
    //Build references based on the environment
    if(getState().env.mode === 'development' || getState().env.mode === 'test'){
      firebaseEnv = 'Development'
    }else if(getState().env.mode === 'production'){
      firebaseEnv = 'Production'
    }

    //Fetch user with this email for current environment
    firestore.collection('Environments').doc(firebaseEnv)
      .collection('Users').where('email', '==', credentials.email).get()
      .then(snapshot => {
        //Check snapshot size, if 0 this user doesnt exists
        if(snapshot.size === 0){
          const notification = {
            type: 'danger',
            title: 'Error al iniciar sesión',
            message: 'Este correo no se encuentra registrado',
          }
          return dispatch({ type: 'SHOW_NOTIFICATION', notification })
        }else { //It exists, proceed to check disabled status for this user in this environment
          snapshot.forEach((doc) => {
            const user = doc.data()
              //If the user is disabled
            if(user.disabled){
              const notification = {
                type: 'danger',
                title: 'Error al iniciar sesión',
                message: 'El correo ingresado se encuentra deshabilitado'
              }
              return dispatch ({type: 'SHOW_NOTIFICATION', notification})
            }
            else{ //If the user isnt disabled
              //Login with email and password
              firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password)
              .then((authUser) => {
                //Successful login
                const action = {
                  user: authUser
                }
                return dispatch({ type: 'LOGIN_SUCCESS', action })
              })
              .catch((err) => {
                //Error while login
                const notification = {
                  type: 'danger',
                  title: "Error al iniciar sesión",
                  message: err.message
                }
                dispatch({ type: 'SHOW_NOTIFICATION', notification })
              })
            }
          })
        }
      })
  }
}
 
export const getUser = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection("superusers").doc(userId)
    .onSnapshot(function (doc) {
      let user = doc.data()
      if (user) {
        user.id = userId
        dispatch({ type: 'LOGIN_SUCCESS', user })
      }
    }, function (error) {
      console.log(error)
    })
  }
}
 
export const signOut = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    await firebase.auth().signOut()
    await firebase.logout()
    dispatch({ type: 'LOGOUT_SUCCESS' })
  }
}

/**
* Action to change password of the current user in session
* @param {Object} obj contains the previous password and new password for re authentication
*/
export const changePassword = (obj) => {
  return async (dispatch, getState, { getFirebase }) => {
      //Deconstruct obj
    const {
      password, 
      newpass
    } = obj

      //Firebase reference
    const firebase = getFirebase()
      //Credential for reauthentication
    const credential = firebase.auth.EmailAuthProvider.credential(
      getState().firebase.auth.email,
      password
    )
    
    try{ //Try incoming async operations

        //Reauthenticate the current user with the credential created previously
      await firebase.auth().currentUser
      .reauthenticateWithCredential(credential)

        //Update current user password
      await firebase.auth().currentUser
      .updatePassword(newpass)

        //Notification with successfull password change
      const notification = {
        type: 'success',
        msg: 'Se ha cambiado la contraseña con éxito'
      }
        //Dispatch the notification
      dispatch({
        type: 'SHOW_NOTIFICATION',
        notification
      })

    }catch(err){
        //Notification with error
      const notification = {
        type: 'error',
        title: 'Error al cambiar la contraseña',
        msg: err
      }
        //Dispatch notification
      dispatch({
        type: 'SHOW_NOTIFICATION',
          notification
      })
    }
  }
}

/**
 * Verifies if the current user in session is an admin
 */
export const verifyAdmin = () => {
  return (dispatch, getState, { getFirestore }) => {
    
    //If the firebase auth is empty
    if(getState().firebase.auth.isEmpty){
      console.log('Reseting admin rigths')
        //Resets admin rights
      return dispatch({
        type: 'IS_NOT_ADMIN'
      })
    }

    console.log('Checking for admin')
      //Reference firestore
    const firestore = getFirestore()
    let adminsRef = firestore.collection('Environments')

      //Build reference based on environmente
    if(getState().env.mode === 'development'){
      adminsRef = adminsRef.doc('Development').collection('Admins')
    }else if(getState().env.mode === 'production'){
      adminsRef = adminsRef.doc('Production').collection('Admins')
    }
    
      //Find if the user is an admin
    adminsRef.doc(getState().firebase.auth.uid).get()
    .then((doc) => {
      if(doc.exists){ //If the doc exists
        return dispatch({
          type: 'IS_ADMIN',
        })
      }else{ //If the doc doesnt exists
        return dispatch({
          type: 'IS_NOT_ADMIN'
        })
      }
    })
  }
}
