const initState = {
    authError: null,
    user: null,
    isAdmin: false,
    emailTaken: false
 }
 
 const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'EMAIL_VALIDATION':
        return {
          ...state,
          emailTaken: action.isTaken
        }
        case 'LOGIN_ERROR':
          console.log(`login error: ${action.err}`)
          return {
            ...state,
            authError: 'Login failed'
          }
        case 'LOGIN_SUCCESS':
          return {
            ...state,
            user: action.user,
            authError: null
          }
        case 'LOGOUT_SUCCESS':
          return {
            ...state,
            authError: null
          }
        case 'IS_ADMIN':
          return {
            ...state,
            isAdmin: true
          }
        case 'IS_NOT_ADMIN':
          return {
            ...state,
            isAdmin: false
          }
       default:
          return state
    }
 }
 
 export default authReducer
