import React from 'react'

//Home components
const Home = React.lazy(() => import("../views/Home/Home"))

//LuckyTrade pages components
const LuckyTradeHome = React.lazy(() => import("../views/LuckyTrade/Home/Home"))
const LuckyTradeIndustries = React.lazy(() => import("../views/LuckyTrade/Industries/Industries"))
const LuckyTradeUs = React.lazy(() => import("../views/LuckyTrade/Us/Us"))
const LuckyTradeServices = React.lazy(() => import("../views/LuckyTrade/Services/Services"))
const LuckyTradeNavbar = React.lazy(() => import("../views/LuckyTrade/Navigation/Navbar"))
const LuckyTradeFooter = React.lazy(() => import("../views/LuckyTrade/Navigation/Footer"))

//Collections management
const Industries = React.lazy(() => import("../views/Industries/IndustriesWrapper"))
const AddIndustry = React.lazy(() => import("../views/Industries/AddIndustry"))
const EditIndustry = React.lazy(() => import("../views/Industries/EditIndustry"))
const Clients = React.lazy(() => import("../views/Clients/ClientsWrapper"))
const AddClient = React.lazy(() => import("../views/Clients/AddClient"))
const EditClient = React.lazy(() => import("../views/Clients/EditClient"))
const Providers = React.lazy(() => import("../views/Providers/ProvidersWrapper"))
const AddProvider = React.lazy(() => import("../views/Providers/AddProvider"))
const EditProvider = React.lazy(() => import("../views/Providers/EditProvider"))

//Edit company
const Company = React.lazy(() => import("../views/Company/Company"))

//Profile components
const UserProfilePage = React.lazy(() => import("../views/Profile/UserProfilePage"))
//const ChangePassword = React.lazy(() => import("../views/Profile/ChangePassword"))

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
/**
 * Regular user routes
 */
const routes = [
  { path: '/', name: 'Home', component: Home, exact: true },
  { path: '/home', name: 'Home', component: Home, exact: true },
  { path: '/lucky-trade-home', name: 'LuckyTrade Home', component: LuckyTradeHome, exact: true },
  { path: '/lucky-trade-industries', name: 'LuckyTrade Industrias', component: LuckyTradeIndustries, exact: true },
  { path: '/lucky-trade-us', name: 'LuckyTrade Nosotros', component: LuckyTradeUs, exact: true },
  { path: '/lucky-trade-services', name: 'LuckyTrade Servicios', component: LuckyTradeServices, exact: true },
  { path: '/lucky-trade-navbar', name: 'LuckyTrade Barra de navegación', component: LuckyTradeNavbar, exact: true },
  { path: '/lucky-trade-footer', name: 'LuckyTrade Footer de navegación', component: LuckyTradeFooter, exact: true },
  { path: '/company', name: 'Datos de contacto', component: Company, exact: true },
  { path: '/industries', name: 'Industrias', component: Industries, exact: true },
  { path: '/industries/add', name: 'Agregar Industria', component: AddIndustry, exact: true },
  { path: '/industries/edit/:id', name: 'Editar Industria', component: EditIndustry, exact: true },
  { path: '/clients', name: 'Clientes', component: Clients, exact: true },
  { path: '/clients/add', name: 'Agregar Cliente', component: AddClient, exact: true },
  { path: '/clients/edit/:id', name: 'Editar Cliente', component: EditClient, exact: true },
  { path: '/providers', name: 'Proveedores', component: Providers, exact: true },
  { path: '/providers/add', name: 'Agregar Proveedor', component: AddProvider, exact: true },
  { path: '/providers/edit/:id', name: 'Editar Proveedor', component: EditProvider, exact: true },
  { path: '/user-profile', name:'User Profile', component: UserProfilePage, exact: true},
];

export default routes;
