export default {
  /**
   * Admin navbar
   */
  items: [
    {
      name: 'Gestionar Usuarios',
      url: '/users',
      icon: 'icon-star'
    },
  ]
}