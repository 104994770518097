import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Container, Row } from 'reactstrap'

class Page404 extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4">404</h1>
                <h4 className="pt-3">Oops! Te has perdido.</h4>
                <p className="text-muted float-left">La página que estas buscando no ha podido ser encontrada</p>
              </div>
              <Link to={"/"}>
                <Button className="mb-3 ml-3" color="primary">
                  ¿Quieres volver al Home?
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page404;
