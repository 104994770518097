import React from 'react'

  //User management components
const Users = React.lazy(() => import('../views/Users/Users'))
const User = React.lazy(() => import('../views/Users/User'))
const AddUser = React.lazy(() => import('../views/Users/AddUser'))

/**
 ** Admin Routes
 */
const routes = [
  { path: '/users', exact: true,  name: 'Usuarios', component: Users }, //All users
  { path: '/users/add', exact: true, name: 'Agregar Usuario', component: AddUser }, //Add User View
  { path: '/users/manage/:id', exact: true, name: 'Detalles de usuario', component: User }, //User details
]

export default routes