import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import * as router from 'react-router-dom';
import { Container } from 'reactstrap';
import { signOut } from '../../store/actions/authActions'
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { compose } from 'redux'
import Login from '../../views/Pages/Login'
import Error404 from '../../views/Pages/Page404'
import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppBreadcrumb2 as AppBreadcrumb,
  AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../navbarConfig/_nav';
import adminNavigation from '../../navbarConfig/_adminNav'
// routes config
import routes from '../../routes/routes';
// Admin routes config
import routesAdmin from '../../routes/adminRoutes'

const DefaultAside = React.lazy(() => import('./DefaultAside'));
const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));

class DefaultLayout extends Component {

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  handleLogout() {
    this.props.signOut()
  }

  render() {

    const { isAdmin, auth } = this.props

      //Wait for sync with firebase
    if(!isLoaded(isAdmin)){
      return <></>
    }

      //Default navbar items and routes
    let navbarItemsToUse = navigation.items
    let routesToUse = routes

    if(isAdmin){ //If the user is admin include admin routes and navbar items
      console.log('Includes admin routes')
      routesToUse = routesToUse.concat(routesAdmin)
      navbarItemsToUse = navbarItemsToUse.concat(adminNavigation.items)
    }

      //Navigation config for AppSidebarNav
    const navigationConfig = {
      items: navbarItemsToUse
    }

    return auth.isEmpty ? 
    <Switch>
      <Route exact path="*" component={Login}/>
    </Switch>
    : (
      <div className="app">
        <AppHeader fixed>
          <Suspense  fallback={this.loading()}>
            <DefaultHeader onLogout={e => this.handleLogout()}/>
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
            <AppSidebarNav navConfig={navigationConfig} {...this.props} router={routesToUse}/>
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routesToUse} router={router}/>
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch>  
                    {routesToUse.map((route, idx) => {
                      
                      return route.component ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={props => (
                            <route.component {...props} />
                          )} />
                      ) : (null);
                  })}
                  <Route exact path="*" component={Error404}/>
                </Switch>
              </Suspense>
            </Container>
          </main>
          <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  
  return {
    auth: state.firebase.auth,
    isAdmin: state.auth.isAdmin,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(DefaultLayout);